import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, Toolbar, IconButton, Drawer, Button, useMediaQuery, CssBaseline, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home'; // ホームアイコン
import CardMembershipIcon from '@mui/icons-material/CardMembership'; // カードメンバーシップアイコン
import logo from '../assets/logo.svg';

const Navbar = () => {
	const [isOpen, setIsOpen] = useState(false);
	const isMobile = useMediaQuery('(max-width:600px)');

	const toggleDrawer = (open) => (event) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		setIsOpen(open);
	};

	const mobileMenu = (
		<Drawer anchor="left" open={isOpen} onClose={toggleDrawer(false)}>
			<List>
				<ListItemButton component={RouterLink} to="/">
					<ListItemIcon sx={{ minWidth: '30px' }}>
						<HomeIcon />
					</ListItemIcon>
					<ListItemText primary="home" />
				</ListItemButton>
				<ListItemButton component={RouterLink} to="/stampcard">
					<ListItemIcon sx={{ minWidth: '30px' }}>
						<CardMembershipIcon />
					</ListItemIcon>
					<ListItemText primary="アチブスタカ" />
				</ListItemButton>
				{/* 他のナビゲーション項目 */}
			</List>
		</Drawer>
	);

	return (
		<div>
			<CssBaseline />
			<AppBar position="static" sx={{
				backgroundColor: 'white',
				boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
			}}>
				<Toolbar>
					{isMobile && (
						<>
							<IconButton
								color="gray"
								aria-label="open drawer"
								edge="start"
								onClick={toggleDrawer(true)}
							>
								<MenuIcon />
							</IconButton>
							<Box sx={{ flexGrow: 1 }} />
							<RouterLink to="/">
								<img src={logo} alt="Site Logo" style={{ height: '50px' }} />
							</RouterLink>
						</>
					)}
					{!isMobile && (
						<>
							<RouterLink to="/">
								<img src={logo} alt="Site Logo" style={{ height: '50px', marginRight: '20px' }} />
							</RouterLink>
							<Button component={RouterLink} to="/" startIcon={<HomeIcon />}
								sx={{ padding: '10px 24px', minWidth: '120px' }}  >
								home
							</Button>
							<Button component={RouterLink} to="/stampcard" startIcon={<CardMembershipIcon />}
								sx={{ padding: '10px 24px', minWidth: '120px' }}  >
								アチブスタカ
							</Button>
							{/* 他のナビゲーションボタン */}
						</>
					)}
				</Toolbar>
			</AppBar>
			{isMobile && mobileMenu}
		</div>
	);
};

export default Navbar;
