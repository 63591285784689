import React, { useState } from 'react';
import { Typography, Box } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import achievementsData from './achievementsData.js';


//マージしたデータを元に、アチーブメントの詳細を表示するコンポーネントを作成
function AchievementDetails({ data }) {

	return (
		<div>
			{data.map((achievement, index) => (
				<Box key={index} mb={1} display="flex" flexDirection="column" alignItems="start">
					<Typography variant="body2" style={{ color: achievement.achieved ? 'green' : 'red' }}>
						{achievement.name} ({achievement.achieved ? "達成済み" : "未達成"})
					</Typography>
					<Typography variant="body2">{achievement.description}</Typography>
					{achievement.requiredAchievements && (
						<Typography variant="caption">
						必要なアチーブメント: {achievement.requiredAchievements.join(", ")}
							必要なアチーブメント: {achievement.progress}
						</Typography>
					)}
				</Box>
			))}
		</div>
	);
}
// アチーブメントをランクまたはグレードに基づいてグループ化する関数
function groupAchievements(achievements) {
	const groups = {};
	achievements.forEach(achievement => {
		const match = achievement.name.match(/(.+：グレード|ランク)\s*\d+/);
		if (match) {
			const baseName = match[1];
			if (!groups[baseName]) {
				groups[baseName] = [];
			}
			groups[baseName].push(achievement);
		}
	});
	return groups;
}

// 各グループの最高ランクを特定し、進捗を計算する関数
function calculateProgress(groups) {
	const progress = {};
	Object.keys(groups).forEach(group => {
		const maxRank = Math.max(...groups[group].map(a => parseInt(a.name.match(/\d+$/)[0], 10)));
		const achieved = groups[group].filter(a => a.status === "達成済み").length;
		progress[group] = { achieved, maxRank, progress: achieved / maxRank };
	});
	return progress;
}


// アプリケーションのメインコンポーネント

function App() {
	const [input, setInput] = useState(localStorage.getItem('userId') || '');
	const [returnData, setData] = useState(null);
	const [errorMessage, setErrorMessage] = useState('');

	const handleInputChange = (e) => {
		setInput(e.target.value);
		setErrorMessage('');
	};

	const extractValidUserId = (input) => {
		if (input.match(/^\d{1,16}$/)) {
			return input;
		}
		const match = input.match(/^https:\/\/jp\.finalfantasyxiv\.com\/lodestone\/character\/(\d+)\/?$/);
		if (match) {
			return match[1];
		}
		return null;
	};

	const validateAndSaveUserId = (userId) => {
		const validUserId = extractValidUserId(userId);
		if (validUserId) {
			localStorage.setItem('userId', validUserId);
			setInput(validUserId);
			setErrorMessage('');
			return true;
		} else {
			setErrorMessage("無効な入力です。キャラクターID（数字）またはロードストーンのキャラクターページURLを正しく入力してください。");
			return false;
		}
	};

	const fetchAchievements = () => {
		//ユーザーIDがおかしかったら終了
		if (!validateAndSaveUserId(input)) {
			return;
		}

		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ url: localStorage.getItem('userId'), appIdentifier: 'profileApp' })
		};

		fetch('/backend/app.py', requestOptions)
			.then(response => response.json())
			.then(json => {
				if (json.error) {
					setErrorMessage(json.message);
				} else {
					const mergedData = mergeDataWithAchievements(json.achievements, achievementsData);


					// ここでグループ化と進捗計算を行う
					const groupedAchievements = groupAchievements(mergedData);
					const progress = calculateProgress(groupedAchievements);
					// ユーザー情報とマージされたアチーブメントデータを一緒にステートに設定
					setData({
						userInfo: {
							characterName: json.character_name,
							homeWorld: json.home_world,
							retrievedAt: json.retrieved_at
						},
						achievements: mergedData,
						progress: progress // 進捗情報もステートに保存
					});
				}
			})
			.catch(error => {
				console.error('Error fetching data: ', error);
				setErrorMessage('データの取得に失敗しました。');
			});
	};

	const mergeDataWithAchievements = (serverData, localData) => {
		return serverData.map((serverAchievement) => {
			const localAchievement = localData[serverAchievement.id];
			return {
				...localAchievement,
				...serverAchievement,
				achieved: serverAchievement.status === "達成済み"
			};
		});
	}

	return (
		<div>
			<div style={{ margin: '20px' }}>
				<TextField
					label="ユーザーID"
					variant="outlined"
					fullWidth
					value={input}
					onChange={handleInputChange}
				/>
				<Button
					style={{ marginTop: '20px' }}
					variant="contained"
					color="primary"
					onClick={fetchAchievements}
				>
					アチーブ情報を取得
				</Button>
				{errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
				{!returnData && !errorMessage && <p>データを読み込んでいます...</p>}
				{returnData && (

					<div id="Achievement_column">
						<h1>{returnData.userInfo.characterName}</h1>
						<p>{returnData.userInfo.homeWorld}</p>
						<p>データ取得日時: {returnData.userInfo.retrievedAt}</p>
						<AchievementDetails data={returnData.achievements} />
					</div>
				)}
			</div>
		</div>
	);
}
export default App;