// 定義済みのアチーブメントデータ
// 各アチーブメントのID、名前、説明、必要なアチーブメント、達成状況、アイコンのパスを保持

const achievementsData = {
		"259": {
			name: "ラノシアの釣り名人：グレード1",
			achievementType: null,
			description: "ラノシアのレベル1-10釣り場で20回釣りをする",
			iconurl: "/29/2962279f5efc5590e6496e9bdfdcfb8ba9bc05e1.png?n6.58"
		},
		"260":{
			name: "ラノシアの釣り名人：グレード2",
			achievementType: null,
			description: "ラノシアのレベル11-20釣り場で100回釣りをする",
			iconurl: "/29/2962279f5efc5590e6496e9bdfdcfb8ba9bc05e1.png?n6.58"
		},
		"261":{
			name: "ラノシアの釣り名人：グレード3",
			achievementType: null,
			description: "ラノシアのレベル21-30釣り場で300回釣りをする",
			iconurl: "/29/2962279f5efc5590e6496e9bdfdcfb8ba9bc05e1.png?n6.58"
		},
		"262":{
			name: "ラノシアの釣り名人：グレード4",
			achievementType: null,
			description: "ラノシアのレベル31-40釣り場で500回釣りをする",
			iconurl: "/29/2962279f5efc5590e6496e9bdfdcfb8ba9bc05e1.png?n6.58"
		},
		"263":{
			name: "ラノシアの釣り名人：グレード5",
			achievementType: null,
			description: "ラノシアのレベル41-50釣り場で1,000回釣りをする",
			iconurl: "/29/2962279f5efc5590e6496e9bdfdcfb8ba9bc05e1.png?n6.58"
		},
		"264":{
			name: "ラノシアの釣り王",
			achievementType: "title",
			description: "アチーブメント「ラノシアの釣り名人：グレード1」～「ラノシアの釣り名人：グレード5」までをすべて達成する",
			iconurl: "/14/14752299507e4ee0e0cdbae83854db435b4fd1d2.png?n6.58",
			requiredAchievements:[259,260,261,262,263],
		},
		"265":{
			name: "黒衣森の釣り名人：グレード1",
			achievementType: null,
			description: "黒衣森のレベル1-10釣り場で20回釣りをする",
			iconurl: "/29/2962279f5efc5590e6496e9bdfdcfb8ba9bc05e1.png?n6.58"
		},
		"266":{
			name: "黒衣森の釣り名人：グレード2",
			achievementType: null,
			description: "黒衣森のレベル11-20釣り場で100回釣りをする",
			iconurl: "/29/2962279f5efc5590e6496e9bdfdcfb8ba9bc05e1.png?n6.58"
		},
		"267":{
			name: "黒衣森の釣り名人：グレード3",
			achievementType: null,
			description: "黒衣森のレベル21-30釣り場で300回釣りをする",
			iconurl: "/29/2962279f5efc5590e6496e9bdfdcfb8ba9bc05e1.png?n6.58"
		},
		"268":{
			name: "黒衣森の釣り名人：グレード4",
			achievementType: null,
			description: "黒衣森のレベル31-40釣り場で500回釣りをする",
			iconurl: "/29/2962279f5efc5590e6496e9bdfdcfb8ba9bc05e1.png?n6.58"
		},
		"269":{
			name: "黒衣森の釣り名人：グレード5",
			achievementType: null,
			description: "黒衣森のレベル41-50釣り場で1,000回釣りをする",
			iconurl: "/29/2962279f5efc5590e6496e9bdfdcfb8ba9bc05e1.png?n6.58"
		},
		"270":{
			name: "黒衣森の釣り王",
			achievementType: "title",
			description: "アチーブメント「黒衣森の釣り名人：グレード1」～「黒衣森の釣り名人：グレード5」までをすべて達成する",
			iconurl: "/14/14752299507e4ee0e0cdbae83854db435b4fd1d2.png?n6.58",
			requiredAchievements:[265,266,267,268,269],
		},
		"271":{
			name: "ザナラーンの釣り名人：グレード1",
			achievementType: null,
			description: "ザナラーンのレベル1-10釣り場で20回釣りをする",
			iconurl: "/29/2962279f5efc5590e6496e9bdfdcfb8ba9bc05e1.png?n6.58"
		},
		"272":{
			name: "ザナラーンの釣り名人：グレード2",
			achievementType: null,
			description: "ザナラーンのレベル11-20釣り場で100回釣りをする",
			iconurl: "/29/2962279f5efc5590e6496e9bdfdcfb8ba9bc05e1.png?n6.58"
		},
		"273":{
			name: "ザナラーンの釣り名人：グレード3",
			achievementType: null,
			description: "ザナラーンのレベル21-30釣り場で300回釣りをする",
			iconurl: "/29/2962279f5efc5590e6496e9bdfdcfb8ba9bc05e1.png?n6.58"
		},
		"274":{
			name: "ザナラーンの釣り名人：グレード4",
			achievementType: null,
			description: "ザナラーンのレベル31-40釣り場で500回釣りをする",
			iconurl: "/29/2962279f5efc5590e6496e9bdfdcfb8ba9bc05e1.png?n6.58"
		},
		"275":{
			name: "ザナラーンの釣り名人：グレード5",
			achievementType: null,
			description: "ザナラーンのレベル41-50釣り場で1,000回釣りをする",
			iconurl: "/29/2962279f5efc5590e6496e9bdfdcfb8ba9bc05e1.png?n6.58"
		},
		"276":{
			name: "ザナラーンの釣り王",
			achievementType: "title",
			description: "アチーブメント「ザナラーンの釣り名人：グレード1」～「ザナラーンの釣り名人：グレード5」までをすべて達成する",
			iconurl: "/14/14752299507e4ee0e0cdbae83854db435b4fd1d2.png?n6.58",
			requiredAchievements:[271,272,273,274,275],
		},
		"277":{
			name: "黄金の釣竿",
			achievementType: "equipment",
			description: "アチーブメント「ラノシアの釣り王」「黒衣森の釣り王」「ザナラーンの釣り王」をすべて達成する",
			iconurl: "/b5/b51cedb109e4250347ae22a610827e7d48b4accc.png?n6.58"
		},
		"1311":{
			name: "蒼天の釣り名人",
			achievementType: null,
			description: "レベル51-60の釣り場で3,000回釣りを行う",
			iconurl: "/29/2962279f5efc5590e6496e9bdfdcfb8ba9bc05e1.png?n6.58"
		},
		"1858":{
			name: "紅蓮の釣り名人",
			achievementType: null,
			description: "レベル61-70の釣り場・漁場で3,000回採集を行う",
			iconurl: "/29/2962279f5efc5590e6496e9bdfdcfb8ba9bc05e1.png?n6.58"
		},
		"2290":{
			name: "漆黒の釣り名人",
			achievementType: null,
			description: "レベル71-80の釣り場・漁場で3,000回採集を行う",
			iconurl: "/29/2962279f5efc5590e6496e9bdfdcfb8ba9bc05e1.png?n6.58"
		},
		"2938":{
			name: "暁月の釣り名人",
			achievementType: null,
			description: "レベル81-90の釣り場・漁場で3,000回採集を行う",
			iconurl: "/29/2962279f5efc5590e6496e9bdfdcfb8ba9bc05e1.png?n6.58"
		},
		"725":{
			name: "手帳好きの漁師：ランク1",
			achievementType: null,
			description: "漁師の採集可能アイテムのうち、40種類を採集し、釣り手帳に印を付ける",
			iconurl: "/dd/dddd9d14b254b3ed75d88391fb7a41d3d0c20d51.png?n6.58"
		},
		"726":{
			name: "手帳好きの漁師：ランク2",
			achievementType: null,
			description: "漁師の採集可能アイテムのうち、80種類を採集し、釣り手帳に印を付ける",
			iconurl: "/dd/dddd9d14b254b3ed75d88391fb7a41d3d0c20d51.png?n6.58"
		},
		"727":{
			name: "手帳好きの漁師：ランク3",
			achievementType: "equipment",
			description: "漁師の採集可能アイテムのうち、160種類を採集し、釣り手帳に印を付ける",
			iconurl: "/dd/dddd9d14b254b3ed75d88391fb7a41d3d0c20d51.png?n6.58"
		},
		"1288":{
			name: "手帳好きの漁師：ランク4",
			achievementType: null,
			description: "漁師の採集可能アイテムのうち、400種類を採集し、釣り手帳に印を付ける",
			iconurl: "/dd/dddd9d14b254b3ed75d88391fb7a41d3d0c20d51.png?n6.58"
		},
		"1731":{
			name: "手帳好きの漁師：ランク5",
			achievementType: "equipment",
			description: "漁師の採集可能アイテムのうち、460種類を採集し、釣り手帳に印を付ける",
			iconurl: "/dd/dddd9d14b254b3ed75d88391fb7a41d3d0c20d51.png?n6.58"
		},
		"2176":{
			name: "手帳好きの漁師：ランク6",
			achievementType: "equipment",
			description: "漁師の採集可能アイテムのうち、780種類を採集し、釣り手帳に印を付ける",
			iconurl: "/dd/dddd9d14b254b3ed75d88391fb7a41d3d0c20d51.png?n6.58"
		},
		"2832":{
			name: "手帳好きの漁師：ランク7",
			achievementType: "equipment",
			description: "漁師の採集可能アイテムのうち、1,140種類を採集し、釣り手帳に印を付ける",
			iconurl: "/dd/dddd9d14b254b3ed75d88391fb7a41d3d0c20d51.png?n6.58"
		},
		"3377":{
			name: "手帳好きの漁師：ランク8",
			achievementType: "title",
			description: "漁師の採集可能アイテムのうち、1,460種類を採集し、釣り手帳に印を付ける",
			iconurl: "/dd/dddd9d14b254b3ed75d88391fb7a41d3d0c20d51.png?n6.58"
		},
		"780":{
			name: "腕利きの漁師：ランク1",
			achievementType: null,
			description: "漁師でラージサイズの水産物を計100回採集する",
			iconurl: "/76/76e0d17da6a2d1992bced089e63aa2cd080bf5b3.png?n6.58"
		},
		"781":{
			name: "腕利きの漁師：ランク2",
			achievementType: null,
			description: "漁師でラージサイズの水産物を計500回採集する",
			iconurl: "/76/76e0d17da6a2d1992bced089e63aa2cd080bf5b3.png?n6.58"
		},
		"782":{
			name: "腕利きの漁師：ランク3",
			achievementType: null,
			description: "漁師でラージサイズの水産物を計3,000回採集する",
			iconurl: "/76/76e0d17da6a2d1992bced089e63aa2cd080bf5b3.png?n6.58"
		},
		"1331":{
			name: "腕利きの漁師：ランク4",
			achievementType: "title",
			description: "漁師でラージサイズの水産物を計10,000回採集する",
			iconurl: "/76/76e0d17da6a2d1992bced089e63aa2cd080bf5b3.png?n6.58"
		},
		"916":{
			name: "太公望への道：ランク1",
			achievementType: null,
			description: "新生エオルゼア、蒼天のイシュガルド、紅蓮のリベレーターのエリアにいるヌシを釣り上げる",
			iconurl: "/ee/ee90cd5436eb67269e0740a1aabede9fecd4f90f.png?n6.58"
		},
		"917":{
			name: "太公望への道：ランク2",
			achievementType: null,
			description: "新生エオルゼア、蒼天のイシュガルド、紅蓮のリベレーターのエリアにいるヌシを16種類、釣り上げる",
			iconurl: "/ee/ee90cd5436eb67269e0740a1aabede9fecd4f90f.png?n6.58"
		},
		"918":{
			name: "太公望への道：ランク3",
			achievementType: null,
			description: "新生エオルゼア、蒼天のイシュガルド、紅蓮のリベレーターのエリアにいるヌシを37種類、釣り上げる",
			iconurl: "/ee/ee90cd5436eb67269e0740a1aabede9fecd4f90f.png?n6.58"
		},
		"1015":{
			name: "太公望への道：ランク4",
			achievementType: null,
			description: "新生エオルゼア、蒼天のイシュガルド、紅蓮のリベレーターのエリアにいるヌシを56種類、釣り上げる",
			iconurl: "/ee/ee90cd5436eb67269e0740a1aabede9fecd4f90f.png?n6.58"
		},
		"1016":{
			name: "太公望への道：ランク5",
			achievementType: null,
			description: "新生エオルゼア、蒼天のイシュガルド、紅蓮のリベレーターのエリアにいるヌシを71種類、釣り上げる",
			iconurl: "/ee/ee90cd5436eb67269e0740a1aabede9fecd4f90f.png?n6.58"
		},
		"1017":{
			name: "太公望への道：ランク6",
			achievementType: null,
			description: "新生エオルゼア、蒼天のイシュガルド、紅蓮のリベレーターのエリアにいるヌシを81種類、釣り上げる",
			iconurl: "/ee/ee90cd5436eb67269e0740a1aabede9fecd4f90f.png?n6.58"
		},
		"1018":{
			name: "太公望への道：ランク7",
			achievementType: null,
			description: "新生エオルゼア、蒼天のイシュガルド、紅蓮のリベレーターのエリアにいるヌシを87種類、釣り上げる",
			iconurl: "/ee/ee90cd5436eb67269e0740a1aabede9fecd4f90f.png?n6.58"
		},
		"1032":{
			name: "太公望への道：ランク8",
			achievementType: null,
			description: "新生エオルゼア、蒼天のイシュガルド、紅蓮のリベレーターのエリアにいるヌシを94種類、釣り上げる",
			iconurl: "/ee/ee90cd5436eb67269e0740a1aabede9fecd4f90f.png?n6.58"
		},
		"1033":{
			name: "太公望への道：ランク9",
			achievementType: "title",
			description: "新生エオルゼア、蒼天のイシュガルド、紅蓮のリベレーターのエリアにいるヌシを100種類、釣り上げる",
			iconurl: "/ee/ee90cd5436eb67269e0740a1aabede9fecd4f90f.png?n6.58"
		},
		"1034":{
			name: "太公望への道：ランク10",
			achievementType: "equipment",
			description: "新生エオルゼア、蒼天のイシュガルド、紅蓮のリベレーターのエリアにいるヌシを106種類、釣り上げる",
			iconurl: "/ee/ee90cd5436eb67269e0740a1aabede9fecd4f90f.png?n6.58"
		},
		"1622":{
			name: "太公望への道：ランク11",
			achievementType: null,
			description: "新生エオルゼア、蒼天のイシュガルド、紅蓮のリベレーターのエリアにいるヌシを119種類、釣り上げる",
			iconurl: "/ee/ee90cd5436eb67269e0740a1aabede9fecd4f90f.png?n6.58"
		},
		"1633":{
			name: "太公望への道：ランク12",
			achievementType: null,
			description: "新生エオルゼア、蒼天のイシュガルド、紅蓮のリベレーターのエリアにいるヌシを135種類、釣り上げる",
			iconurl: "/ee/ee90cd5436eb67269e0740a1aabede9fecd4f90f.png?n6.58"
		},
		"1732":{
			name: "太公望への道：ランク13",
			achievementType: null,
			description: "新生エオルゼア、蒼天のイシュガルド、紅蓮のリベレーターのエリアにいるヌシを156種類、釣り上げる",
			iconurl: "/ee/ee90cd5436eb67269e0740a1aabede9fecd4f90f.png?n6.58"
		},
		"2079":{
			name: "太公望への道：ランク14",
			achievementType: null,
			description: "新生エオルゼア、蒼天のイシュガルド、紅蓮のリベレーターのエリアにいるヌシを170種類、釣り上げる",
			iconurl: "/ee/ee90cd5436eb67269e0740a1aabede9fecd4f90f.png?n6.58"
		},
		"2125":{
			name: "太公望への道：ランク15",
			achievementType: null,
			description: "新生エオルゼア、蒼天のイシュガルド、紅蓮のリベレーターのエリアにいるヌシを185種類、釣り上げる",
			iconurl: "/ee/ee90cd5436eb67269e0740a1aabede9fecd4f90f.png?n6.58"
		},
		"2245":{
			name: "太公望への道：ランク16",
			achievementType: "title",
			description: "新生エオルゼア、蒼天のイシュガルド、紅蓮のリベレーターのエリアにいるヌシを204種類、釣り上げる",
			iconurl: "/ee/ee90cd5436eb67269e0740a1aabede9fecd4f90f.png?n6.58"
		},
		"2486":{
			name: "第一世界の太公望：ランク1",
			achievementType: null,
			description: "漆黒のヴィランズのエリアにいるヌシを8種類、釣り上げる",
			iconurl: "/ee/ee90cd5436eb67269e0740a1aabede9fecd4f90f.png?n6.58"
		},
		"2588":{
			name: "第一世界の太公望：ランク2",
			achievementType: null,
			description: "漆黒のヴィランズのエリアにいるヌシを14種類、釣り上げる",
			iconurl: "/ee/ee90cd5436eb67269e0740a1aabede9fecd4f90f.png?n6.58"
		},
		"2644":{
			name: "第一世界の太公望：ランク3",
			achievementType: null,
			description: "漆黒のヴィランズのエリアにいるヌシを22種類、釣り上げる",
			iconurl: "/ee/ee90cd5436eb67269e0740a1aabede9fecd4f90f.png?n6.58"
		},
		"2716":{
			name: "第一世界の太公望：ランク4",
			achievementType: null,
			description: "漆黒のヴィランズのエリアにいるヌシを28種類、釣り上げる",
			iconurl: "/ee/ee90cd5436eb67269e0740a1aabede9fecd4f90f.png?n6.58"
		},
		"2833":{
			name: "第一世界の太公望：ランク5",
			achievementType: "title",
			description: "漆黒のヴィランズのエリアにいるヌシを45種類、釣り上げる",
			iconurl: "/ee/ee90cd5436eb67269e0740a1aabede9fecd4f90f.png?n6.58"
		},
		"2834":{
			name: "真太公望への道",
			achievementType: "title",
			description: "アチーブメント「太公望への道：ランク16」「第一世界の太公望：ランク5」をすべて達成する",
			iconurl: "/c1/c1b0409c52c69405969bff6df2c8da68ec405076.png?n6.58"
		},
		"3077":{
			name: "暁月の太公望：ランク1",
			achievementType: null,
			description: "暁月のフィナーレのエリアにいるヌシを7種類、釣り上げる",
			iconurl: "/ee/ee90cd5436eb67269e0740a1aabede9fecd4f90f.png?n6.58"
		},
		"3187":{
			name: "暁月の太公望：ランク2",
			achievementType: null,
			description: "暁月のフィナーレのエリアにいるヌシを23種類、釣り上げる",
			iconurl: "/ee/ee90cd5436eb67269e0740a1aabede9fecd4f90f.png?n6.58"
		},
		"3378":{
			name: "暁月の太公望：ランク3",
			achievementType: "title",
			description: "暁月のフィナーレのエリアにいるヌシを40種類、釣り上げる",
			iconurl: "/ee/ee90cd5436eb67269e0740a1aabede9fecd4f90f.png?n6.58"
		},
		"3379":{
			name: "超太公望への道",
			achievementType: "title",
			description: "アチーブメント「真太公望への道」「暁月の太公望：ランク3」をすべて達成する",
			iconurl: "/32/328f9c8bb4a5a13a970f1c28372b00dffc846cf7.png?n6.58"
		},
		"2519":{
			name: "蒼天街の漁師：ランク1",
			achievementType: null,
			description: "イシュガルド復興において、漁師で技巧点を50,000点獲得する",
			iconurl: "/ab/abbe6dfa6644697f429dfbbd1daeab0e68787bd7.png?n6.58"
		},
		"2520":{
			name: "蒼天街の漁師：ランク2",
			achievementType: null,
			description: "イシュガルド復興において、漁師で技巧点を150,000点獲得する",
			iconurl: "/ab/abbe6dfa6644697f429dfbbd1daeab0e68787bd7.png?n6.58"
		},
		"2521":{
			name: "蒼天街の漁師マスター",
			achievementType: "title",
			description: "イシュガルド復興において、漁師で技巧点を500,000点獲得する",
			iconurl: "/ab/abbe6dfa6644697f429dfbbd1daeab0e68787bd7.png?n6.58"
		},
		"2547":{
			name: "属性を知る漁師：ランク1",
			achievementType: null,
			description: "漁師で天候が焔霊風、土霊風、雷霊風、嵐霊風の時のみ釣ることができる魚を、計5匹釣り上げる",
			iconurl: "/6d/6d22d9c481fefe39717cd54e8b431783b0d2a01f.png?n6.58"
		},
		"2548":{
			name: "属性を知る漁師：ランク2",
			achievementType: null,
			description: "漁師で天候が焔霊風、土霊風、雷霊風、嵐霊風の時のみ釣ることができる魚を、計50匹釣り上げる",
			iconurl: "/6d/6d22d9c481fefe39717cd54e8b431783b0d2a01f.png?n6.58"
		},
		"2549":{
			name: "属性を知る漁師：ランク3",
			achievementType: null,
			description: "漁師で天候が焔霊風、土霊風、雷霊風、嵐霊風の時のみ釣ることができる魚を、計100匹釣り上げる",
			iconurl: "/6d/6d22d9c481fefe39717cd54e8b431783b0d2a01f.png?n6.58"
		},
		"2537":{
			name: "ニューネスト居住区の漁師",
			achievementType: "title",
			description: "第二次復興事業計画用の、特注魚を300匹検品する",
			iconurl: "/55/55c258357f7f0d7861e87dd8b9cda234d048e837.png?n6.58"
		},
		"2658":{
			name: "フェザーフォール商業区の漁師",
			achievementType: "title",
			description: "第三次復興事業計画用の、特注魚を300匹検品する",
			iconurl: "/53/538ccf648365215d1b2859a422d5827c4a9a597c.png?n6.58"
		},
		"2817":{
			name: "リズンソング文化区の漁師",
			achievementType: "title",
			description: "第四次復興事業計画用の、特注魚を300匹検品する",
			iconurl: "/7d/7d166ec4051301630f64c3476c5b70a6c7a9a811.png?n6.58"
		},
		"2553":{
			name: "ガラディオン湾沖合の魚拓王",
			achievementType: null,
			description: "ガラディオン湾沖合で天候が幻海流ではない時に釣れる魚を、全種類釣り上げる",
			iconurl: "/b5/b5492cb8fdb709851ebc8938ca505e8093cc40c3.png?n6.58"
		},
		"2603":{
			name: "ガラディオン湾沖合の幻海王",
			achievementType: null,
			description: "ガラディオン湾沖合で天候が幻海流の時に釣れる魚を、全種類釣り上げる",
			iconurl: "/b5/b5492cb8fdb709851ebc8938ca505e8093cc40c3.png?n6.58"
		},
		"2554":{
			name: "メルトール海峡南の魚拓王",
			achievementType: null,
			description: "メルトール海峡南で天候が幻海流ではない時に釣れる魚を、全種類釣り上げる",
			iconurl: "/b5/b5492cb8fdb709851ebc8938ca505e8093cc40c3.png?n6.58"
		},
		"2604":{
			name: "メルトール海峡南の幻海王",
			achievementType: null,
			description: "メルトール海峡南で天候が幻海流の時に釣れる魚を、全種類釣り上げる",
			iconurl: "/b5/b5492cb8fdb709851ebc8938ca505e8093cc40c3.png?n6.58"
		},
		"2556":{
			name: "メルトール海峡北の魚拓王",
			achievementType: null,
			description: "メルトール海峡北で天候が幻海流ではない時に釣れる魚を、全種類釣り上げる",
			iconurl: "/b5/b5492cb8fdb709851ebc8938ca505e8093cc40c3.png?n6.58"
		},
		"2606":{
			name: "メルトール海峡北の幻海王",
			achievementType: null,
			description: "メルトール海峡北で天候が幻海流の時に釣れる魚を、全種類釣り上げる",
			iconurl: "/b5/b5492cb8fdb709851ebc8938ca505e8093cc40c3.png?n6.58"
		},
		"2555":{
			name: "ロータノ海沖合の魚拓王",
			achievementType: null,
			description: "ロータノ海沖合で天候が幻海流ではない時に釣れる魚を、全種類釣り上げる",
			iconurl: "/b5/b5492cb8fdb709851ebc8938ca505e8093cc40c3.png?n6.58"
		},
		"2605":{
			name: "ロータノ海沖合の幻海王",
			achievementType: null,
			description: "ロータノ海沖合で天候が幻海流の時に釣れる魚を、全種類釣り上げる",
			iconurl: "/b5/b5492cb8fdb709851ebc8938ca505e8093cc40c3.png?n6.58"
		},
		"2748":{
			name: "シェルダレー諸島沖合の魚拓王",
			achievementType: null,
			description: "シェルダレー諸島沖合で天候が幻海流ではない時に釣れる魚を、全種類釣り上げる",
			iconurl: "/b5/b5492cb8fdb709851ebc8938ca505e8093cc40c3.png?n6.58"
		},
		"2751":{
			name: "シェルダレー諸島沖合の幻海王",
			achievementType: null,
			description: "シェルダレー諸島沖合で天候が幻海流の時に釣れる魚を、全種類釣り上げる",
			iconurl: "/b5/b5492cb8fdb709851ebc8938ca505e8093cc40c3.png?n6.58"
		},
		"2749":{
			name: "緋汐海沖合の魚拓王",
			achievementType: null,
			description: "緋汐海沖合で天候が幻海流ではない時に釣れる魚を、全種類釣り上げる",
			iconurl: "/b5/b5492cb8fdb709851ebc8938ca505e8093cc40c3.png?n6.58"
		},
		"2752":{
			name: "緋汐海沖合の幻海王",
			achievementType: null,
			description: "緋汐海沖合で天候が幻海流の時に釣れる魚を、全種類釣り上げる",
			iconurl: "/b5/b5492cb8fdb709851ebc8938ca505e8093cc40c3.png?n6.58"
		},
		"2750":{
			name: "ロズリト湾沖合の魚拓王",
			achievementType: null,
			description: "ロズリト湾沖合で天候が幻海流ではない時に釣れる魚を、全種類釣り上げる",
			iconurl: "/b5/b5492cb8fdb709851ebc8938ca505e8093cc40c3.png?n6.58"
		},
		"2753":{
			name: "ロズリト湾沖合の幻海王",
			achievementType: null,
			description: "ロズリト湾沖合で天候が幻海流の時に釣れる魚を、全種類釣り上げる",
			iconurl: "/b5/b5492cb8fdb709851ebc8938ca505e8093cc40c3.png?n6.58"
		},
		"3256":{
			name: "セイレーン海沖合の魚拓王",
			achievementType: null,
			description: "セイレーン海沖合で天候が幻海流ではない時に釣れる魚を、全種類釣り上げる",
			iconurl: "/b5/b5492cb8fdb709851ebc8938ca505e8093cc40c3.png?n6.58"
		},
		"3260":{
			name: "セイレーン海沖合の幻海王",
			achievementType: null,
			description: "セイレーン海沖合で天候が幻海流の時に釣れる魚を、全種類釣り上げる",
			iconurl: "/b5/b5492cb8fdb709851ebc8938ca505e8093cc40c3.png?n6.58"
		},
		"3257":{
			name: "クガネ沖合の魚拓王",
			achievementType: null,
			description: "クガネ沖合で天候が幻海流ではない時に釣れる魚を、全種類釣り上げる",
			iconurl: "/b5/b5492cb8fdb709851ebc8938ca505e8093cc40c3.png?n6.58"
		},
		"3261":{
			name: "クガネ沖合の幻海王",
			achievementType: null,
			description: "クガネ沖合で天候が幻海流の時に釣れる魚を、全種類釣り上げる",
			iconurl: "/b5/b5492cb8fdb709851ebc8938ca505e8093cc40c3.png?n6.58"
		},
		"3258":{
			name: "紅玉海沖合の魚拓王",
			achievementType: null,
			description: "紅玉海沖合で天候が幻海流ではない時に釣れる魚を、全種類釣り上げる",
			iconurl: "/b5/b5492cb8fdb709851ebc8938ca505e8093cc40c3.png?n6.58"
		},
		"3262":{
			name: "紅玉海沖合の幻海王",
			achievementType: null,
			description: "紅玉海沖合で天候が幻海流の時に釣れる魚を、全種類釣り上げる",
			iconurl: "/b5/b5492cb8fdb709851ebc8938ca505e8093cc40c3.png?n6.58"
		},
		"3259":{
			name: "ヤンサ下流の魚拓王",
			achievementType: null,
			description: "ヤンサ下流で天候が幻海流ではない時に釣れる魚を、全種類釣り上げる",
			iconurl: "/b5/b5492cb8fdb709851ebc8938ca505e8093cc40c3.png?n6.58"
		},
		"3263":{
			name: "ヤンサ下流の幻海王",
			achievementType: null,
			description: "ヤンサ下流で天候が幻海流の時に釣れる魚を、全種類釣り上げる",
			iconurl: "/b5/b5492cb8fdb709851ebc8938ca505e8093cc40c3.png?n6.58"
		},
		"2557":{
			name: "世界を釣る者：ランク1",
			achievementType: null,
			description: "オーシャンフィッシングで、釣果点を累計10,000ポイント記録する",
			iconurl: "/00/0040bf4b6f2f3b7157820d01c6f439386008af17.png?n6.58"
		},
		"2558":{
			name: "世界を釣る者：ランク2",
			achievementType: null,
			description: "オーシャンフィッシングで、釣果点を累計100,000ポイント記録する",
			iconurl: "/00/0040bf4b6f2f3b7157820d01c6f439386008af17.png?n6.58"
		},
		"2559":{
			name: "世界を釣る者：ランク3",
			achievementType: null,
			description: "オーシャンフィッシングで、釣果点を累計500,000ポイント記録する",
			iconurl: "/00/0040bf4b6f2f3b7157820d01c6f439386008af17.png?n6.58"
		},
		"2757":{
			name: "世界を釣る者：ランク4",
			achievementType: "minion",
			description: "オーシャンフィッシングで、釣果点を累計1,000,000ポイント記録する",
			iconurl: "/00/0040bf4b6f2f3b7157820d01c6f439386008af17.png?n6.58"
		},
		"2758":{
			name: "世界を釣る者：ランク5",
			achievementType: "title",
			description: "オーシャンフィッシングで、釣果点を累計3,000,000ポイント記録する",
			iconurl: "/00/0040bf4b6f2f3b7157820d01c6f439386008af17.png?n6.58"
		},
		"2560":{
			name: "オーシャンフィッシャー：ランク1",
			achievementType: "minion",
			description: "オーシャンフィッシング（近海）で、1回の航海で5,000ポイント以上の合計釣果点を記録する",
			iconurl: "/1b/1b8597d76e00857d619f89dc256e4a0ba28e5b74.png?n6.58"
		},
		"2561":{
			name: "オーシャンフィッシャー：ランク2",
			achievementType: "mount",
			description: "オーシャンフィッシング（近海）で、1回の航海で10,000ポイント以上の合計釣果点を記録する",
			iconurl: "/1b/1b8597d76e00857d619f89dc256e4a0ba28e5b74.png?n6.58"
		},
		"2562":{
			name: "オーシャンフィッシャー：ランク3",
			achievementType: "title",
			description: "オーシャンフィッシング（近海）で、1回の航海で16,000ポイント以上の合計釣果点を記録する",
			iconurl: "/1b/1b8597d76e00857d619f89dc256e4a0ba28e5b74.png?n6.58"
		},
		"2759":{
			name: "オーシャンフィッシャー：ランク4",
			achievementType: "title",
			description: "オーシャンフィッシング（近海）で、1回の航海で20,000ポイント以上の合計釣果点を記録する",
			iconurl: "/1b/1b8597d76e00857d619f89dc256e4a0ba28e5b74.png?n6.58"
		},
		"3264":{
			name: "ビッグオーシャンフィッシャー：ランク1",
			achievementType: null,
			description: "オーシャンフィッシング（遠洋）で、1回の航海で5,000ポイント以上の合計釣果点を記録する",
			iconurl: "/8d/8d5356f914a98fda957e8d6c6710395669aad58c.png?n6.58"
		},
		"3265":{
			name: "ビッグオーシャンフィッシャー：ランク2",
			achievementType: "minion",
			description: "オーシャンフィッシング（遠洋）で、1回の航海で10,000ポイント以上の合計釣果点を記録する",
			iconurl: "/8d/8d5356f914a98fda957e8d6c6710395669aad58c.png?n6.58"
		},
		"3266":{
			name: "ビッグオーシャンフィッシャー：ランク3",
			achievementType: "title",
			description: "オーシャンフィッシング（遠洋）で、1回の航海で16,000ポイント以上の合計釣果点を記録する",
			iconurl: "/8d/8d5356f914a98fda957e8d6c6710395669aad58c.png?n6.58"
		},
		"2563":{
			name: "オクトパストラベラー",
			achievementType: "title",
			description: "オーシャンフィッシング（近海）にて、オクトパストラベラーを達成する",
			iconurl: "/dc/dcd1a8a2a731da1f2b7123279a671f3e1f8b8fe1.png?n6.58"
		},
		"2564":{
			name: "シャークハンター",
			achievementType: "title",
			description: "オーシャンフィッシング（近海）にて、シャークハンターを達成する",
			iconurl: "/1a/1a1af1901cd990981be90fed0f6d6db70d022f04.png?n6.58"
		},
		"2565":{
			name: "クラゲマニア",
			achievementType: "title",
			description: "オーシャンフィッシング（近海）にて、クラゲマニアを達成する",
			iconurl: "/df/df4fbb00fc8eeb1d7cb933abee95c238d535733c.png?n6.58"
		},
		"2566":{
			name: "シードラゴンキラー",
			achievementType: "title",
			description: "オーシャンフィッシング（近海）にて、シードラゴンキラーを達成する",
			iconurl: "/89/8955511c4fa121fef3aed966099c6b205662a9f8.png?n6.58"
		},
		"2754":{
			name: "バルーンキャッチャー",
			achievementType: "title",
			description: "オーシャンフィッシング（近海）にて、バルーンキャッチャーを達成する",
			iconurl: "/d5/d5c083e442d34d637bb5809e12aee5dc9336ba1e.png?n6.58"
		},
		"2755":{
			name: "蟹工船",
			achievementType: "title",
			description: "オーシャンフィッシング（近海）にて、蟹工船を達成する",
			iconurl: "/d6/d61aed5a56ba71a46909eb40a89e7d9d0f36cd6e.png?n6.58"
		},
		"2756":{
			name: "エイエイオー",
			achievementType: "title",
			description: "オーシャンフィッシング（近海）にて、エイエイオーを達成する",
			iconurl: "/c2/c24a0b3d07fe47f08d8343c71119343c0c57e7ca.png?n6.58"
		},
		"3267":{
			name: "貝殻収集家",
			achievementType: "title",
			description: "オーシャンフィッシング（遠洋）にて、貝殻収集家を達成する",
			iconurl: "/0d/0d7166fb66b003c6bfdfb374d2e3502d15fbf557.png?n6.58"
		},
		"3268":{
			name: "スルメドラグーン",
			achievementType: "title",
			description: "オーシャンフィッシング（遠洋）にて、スルメドラグーンを達成する",
			iconurl: "/27/27f7d84b672d4b3a63f397354fd21faea77be73d.png?n6.58"
		},
		"3269":{
			name: "海老散らない",
			achievementType: "title",
			description: "オーシャンフィッシング（遠洋）にて、海老散らないを達成する",
			iconurl: "/c1/c10d64f04acd78e8b9c0eb94123846ae17afbf2c.png?n6.58"
		},
		"2888":{
			name: "カモメ",
			achievementType: "minion",
			description: "オーシャンフィッシングにて、カモメの群れに3回遭遇する",
			iconurl: "/98/985fb877ef8d52e5b87070dbc46b886a57add0b0.png?n6.58"
		},
		"2889":{
			name: "魚をくれてありがとう",
			achievementType: "minion",
			description: "オーシャンフィッシングにて、イルカの群れに3回遭遇する",
			iconurl: "/98/985fb877ef8d52e5b87070dbc46b886a57add0b0.png?n6.58"
		}
};

export default achievementsData;
