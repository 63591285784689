import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import StampCardApp from './Apps/stampcard_of/App';
import TestApp from './Apps/testapp/App';
import ListApp from './Apps/listapp/App';
import Navbar from './components/Navbar';
import Footer from './components/Footer'; // Footerのインポート
import { Container, Link, Box, Typography } from '@mui/material';
import ToolCards from './components/ToolCards';
import './App.css'; // CSSファイルをインポート
import CustomThemeProvider from './components/CustomThemeProvider';
import HomeIcon from '@mui/icons-material/Home'; // ホームアイコン
import { Helmet } from 'react-helmet';

const HomePage = () => {
  return <div><h1>
    <HomeIcon sx={{ mr: 1 }} />home</h1>
    <p>FINAL FANTASY XIV漁師のためのスタンプカードジェネレーターを作成しました。</p>
    <ToolCards /></div>;
};


function App() {
  return (
    <CustomThemeProvider>
      <Router>

        <Helmet>
          <title>PONPE FFXIV漁師スタカ</title>
          <meta name="description" content="漁師PTアチブスタカなどFF14のなんかをいろいろ作っていくかものサイト" />
          <meta property="og:title" content="PONPE" />
          <meta property="og:description" content="漁師PTアチブスタカなどFF14のなんかをいろいろ作っていくかものサイト" />
          <meta property="og:image" content="https://ponpe14.com/assets/stampcard_of.png" />
        </Helmet>
        <div className="app-container">
          <Navbar />
          <Container id="container" maxWidth="md" sx={{ pb: 6 }}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/stampcard" element={<StampCardApp />} />
              <Route path="/testapp"  element={<TestApp />}  />
              <Route path="/listapp"  element={<ListApp />}  />
            </Routes>
          </Container>
        </div>

        <Box component="footer">
          <Container maxWidth="lg">
            <Typography variant="body2" color="text.secondary" component="span">
              お問い合わせ{' '}
              <Link href="https://bsky.app/profile/henihaff14.bsky.social" target="_blank" rel="noopener noreferrer" variant="body2">
                @henihaff14.bsky.social
              </Link>
            </Typography>
          </Container>
        </Box>
        <Footer /> {/* フッターの追加 */}
      </Router>
    </CustomThemeProvider>
  );
}

export default App;
