import React, { useRef, useEffect } from 'react';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import cardBackground from '../assets/stamp-card-background.png';

// スタンプ画像のインポート
const stampImages = Array.from({ length: 10 }).map((_, index) =>
	require(`../assets/stamps/stamp${index + 1}.png`)
);
/* const cardBackground = require('../assets/stamp-card-background.png'); */

const StampCard = ({ playerName, selectedStamps, onStampChange }) => {
	const stampCardRef = useRef(null);
	const nameRef = useRef(null);

	useEffect(() => {
		// テキストサイズを調整する関数
		const adjustTextSize = () => {
			// スタンプカードの現在の幅を取得
			const cardWidth = stampCardRef.current.offsetWidth; // スタンプカードの幅
			const maxWidth = cardWidth * 0.22; // スタンプカードの幅の22%

			const fontSize = cardWidth / 500 * 11; // 11pxが基準のフォントサイズ
			// 計算されたフォントサイズを適用（pxを文字列として追加）
			nameRef.current.style.fontSize = `${fontSize}px`;


			nameRef.current.style.transform = 'scaleX(1)';
			const nameWidth = nameRef.current.scrollWidth; // 名前の要素の幅
			let scale = 1;

			nameRef.current.style.transformOrigin = 'left';
			if (nameWidth > maxWidth) {
				scale = maxWidth / nameWidth; // 横幅を縮小するスケールを計算
				nameRef.current.style.transform = `scaleX(${scale})`;
			}

		}
		// 初回実行とウィンドウのリサイズ時にテキストサイズを調整
		adjustTextSize(); // 名前のサイズ調整を実行
		window.addEventListener('resize', adjustTextSize); // ウィンドウのリサイズ時にも調整
		// コンポーネントのアンマウント時、または依存関係が変更された際にリスナーをクリーンアップ
		return () => {
			window.removeEventListener('resize', adjustTextSize); // イベントリスナーを削除
		};
	}, [playerName]); // playerNameを依存配列に追加

	// スタンプの選択状態が変更された時にローカルストレージを更新
	useEffect(() => {
		localStorage.setItem('selectedStamps', JSON.stringify(selectedStamps));
	}, [selectedStamps]);

	// スタンプの選択/解除を扱う関数
	const handleStampClick = (index) => {
		// 新しいスタンプの状態配列を生成
		const newStamps = selectedStamps.map((selected, idx) =>
			idx === index ? !selected : selected
		);
		// 親コンポーネントの状態更新関数を呼び出し
		onStampChange(newStamps);
	};

	return (
		<Box
			id="stampCard" ref={stampCardRef}
			sx={{
				backgroundImage: `url(${cardBackground})`,
				backgroundSize: 'cover',
				width: '100%', // レスポンシブ対応のため100%に設定
				aspectRatio: '8 / 5', // 縦横比を8:5に固定
				position: 'relative',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
			}}
		>
     {/* <img src={cardBackground} alt="Card Background" style={{ position: 'absolute', width: '100%', height: '100%', zIndex: -1 }} /> */}

			{/* スタンプ画像の配置 */}
			{selectedStamps.map((selected, index) => (
				<Tooltip
					key={index}
					title={selected ? "スタンプを取り消す" : "スタンプを押す"}
					placement="top"
					arrow
				>
					<Button
						key={index}
						onClick={() => handleStampClick(index)}
						sx={{
							position: 'absolute',
							minWidth: '0px',// 例としての幅の計算
							width: 'calc(15.5% )', // 例としての幅の計算
							aspectRatio: '1 / 1', // 正方形の縦横比を保持
							// 2行×5列の配置を想定したtopとleftの計算
							top: `calc(${index < 5 ? '33.8%' : '62%'})`, // 上段または下段の位置
							left: `calc(${index % 5} * 17.3% + 7.1%)`, // 左からの位置
							padding: 0,
							margin: 0,
							borderRadius: '50%',
						}}
					>
						{selected && (
							<img
								src={stampImages[index]}
								alt={`スタンプ ${index + 1}`}
								style={{ width: '100%', height: '100%' }}
							/>
						)}
					</Button>
				</Tooltip>
			))}

			<Typography
				ref={nameRef}
				sx={{
					whiteSpace: 'nowrap',
					position: 'absolute',
					left: '68%',
					top: '92%',
					lineHeight: 1,
					fontSize: '0.8rem',
				}}
			>
				{playerName || ''}
			</Typography>


		</Box>
	);
};

export default StampCard;
