import React from 'react';
import { Box, Button,  } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { toPng, toBlob } from 'html-to-image';
import download from 'downloadjs';
// スリープ関数の定義
/* const sleep = ms => new Promise(resolve => setTimeout(resolve, ms)); */


const DownloadButton = ({ disabled, loading, elementId, fileName, onDownloadSuccess, onDownloadError, ...props }) => {
	const handleDownload = async () => {
		const element = document.getElementById(elementId); // ダウンロード対象の要素を取得
		if (!element) {
			onDownloadError && onDownloadError('要素が見つかりません。');
			return;
		}

		//const originalTransform = element.style.transform;// 元のスタイルを保存
		//element.style.transform = 'scale(1)';// スケールを解除（または調整）

		try {
			if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
				// iOSの場合の処理
				//const blob = await toBlob(element, { width: 800, height: 500 });
				await toBlob(element); // 最初の実行（結果は使用しない）
				await toBlob(element); // 最初の実行（結果は使用しない）
				await toBlob(element); // 最初の実行（結果は使用しない）
				const blob = await toBlob(element, {
					scale: 1, // 出力画像のスケールを n倍に設定　効いてない気がする
					pixelRatio: 2, // 出力画像のピクセル比率を n倍に設定
					width: element.offsetWidth,
					height: element.offsetHeight,
					canvasWidth: element.offsetWidth,
					canvasHeight: element.offsetHeight,
					skipFonts: true,
					fontEmbedCSS: ''
				});
				if (blob) {
/* 					await sleep(0); // 1秒間遅延 */
					const file = new File([blob], `${fileName}.png`, { type: 'image/png' });
					if (navigator.share) {
						await navigator.share({
							files: [file],
							title: 'Download Image',
							text: 'Image downloaded successfully.',
						});
						onDownloadSuccess && onDownloadSuccess();
					} else {
						// navigator.shareが利用不可能な場合のフォールバック処理
						alert('このデバイスでは共有機能がサポートされていません。');
						onDownloadError && onDownloadError('共有機能がサポートされていません。');
					}
				} else {
					alert('保存するデータがありません');
				}
			} else {
				// 非iOSデバイスの場合の既存処理
				const image = await toPng(element, {
					scale: 0.5, // 出力画像のスケールを n倍に設定　効いてない気がする
					pixelRatio: 1.5, // 出力画像のピクセル比率を n倍に設定
					width: element.offsetWidth,
					height: element.offsetHeight,
				});
				download(image, `${fileName}.png`);
			}
		} finally {
			// 処理後に元のスタイルに戻す
			//element.style.transform = originalTransform;
		}
	};

	return (

		<Box
			sx={{
				marginTop: 2,
				width: '100%',
			}}
		>
			<Button
				onClick={handleDownload}
				variant="contained"
                disabled={disabled || loading}
				{...props}
				color="primary"
				size="large"
				sx={{
					borderRadius: 2,
					boxShadow: '0px 2px 5px rgba(0,0,0,0.2)',
					textTransform: 'none',
					fontWeight: 'bold',
					':hover': {
						boxShadow: '0px 3px 8px rgba(0,0,0,0.3)',
					},
				}}
				startIcon={<CloudDownloadIcon sx={{ opacity: loading ? 0.5 : 1 }} />}

			>
                ダウンロード
			</Button>
		</Box>
	);
};

export default DownloadButton;
