// ToolCards.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Card, CardActionArea, CardMedia, CardContent, Typography, Container } from '@mui/material';
import stampcard_of from '../assets/stampcard_of.png';

// ツール情報の例
const tools = [
  { title: 'アチブスタカ', description: 'オーシャンフィッシングのアチーブメント用スタンプカードです。', imageUrl: stampcard_of, link: '/stampcard',subtitle:'OceanFishing' },
/*   { title: 'ツール2', description: 'ツール2の説明', imageUrl: 'tool2.jpg', link: '/apps/tool2',subtitle:'OceanFishing' },
  { title: 'ツール3', description: 'ツール3の説明', imageUrl: 'tool3.jpg', link: '/apps/tool3',subtitle:'OceanFishing' }, */
];

export default function ToolCards() {
  return (
    <Container>
      <Grid container spacing={4}>
        {tools.map((tool) => (
          <Grid item xs={12} sm={6} md={4} key={tool.title}>
            <Card sx={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)', }}>
              <CardActionArea component={Link} to={tool.link}>
                <CardMedia
                  component="img"
                  height="140"
                  image={tool.imageUrl}
                  alt={tool.title}
                />
                <CardContent>{tool.subtitle}
                  <Typography gutterBottom variant="h5" component="div">
                  {tool.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    {tool.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
