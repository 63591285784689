import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { List, Card, CardContent, ListItem, ListItemIcon, ListItemText, Typography, Tooltip } from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoIcon from '@mui/icons-material/Info';

const styles = {
    card: {
        marginBottom: 10,
        backgroundColor: '#f9f9f9',
    },
    achieved: {
        color: '#4caf50',
    },
    notAchieved: {
        color: '#f44336',
    },
    infoIcon: {
        fontSize: '1.2rem',
        marginLeft: '5px',  // To ensure some spacing from the text
        verticalAlign: 'middle'  // Align with the text
    }
};

function MyComponent() {

    // ローカルストレージからユーザーIDを読み込み、存在しない場合は空文字列を初期値とする
    const [input, setInput] = useState(localStorage.getItem('userId') || '');
    const [data, setData] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');  // エラーメッセージ用のステート

    const handleInputChange = (e) => {
        setInput(e.target.value);  // 入力値の変更をハンドル
        setErrorMessage('');  // エラーメッセージをクリア
    };

    const validateAndSaveUserId = (userId) => {
        if (userId.match(/^\d{1,16}$/)) {
            localStorage.setItem('userId', userId);  // ローカルストレージに保存
            return true;
        } else {
            const match = userId.match(/^https:\/\/jp\.finalfantasyxiv\.com\/lodestone\/character\/(\d+)\/?$/);
            if (match) {
                const extractedId = match[1];
                localStorage.setItem('userId', extractedId);
                setInput(extractedId);  // 抽出したユーザーIDを入力フィールドに設定
                return true;
            } else {
                setErrorMessage("無効な入力です。キャラクターID（数字）またはロードストーンのキャラクターページURLを正しく入力してください。");
                return false;
            }
        }
    };

    const fetchTitle = () => {
        if (validateAndSaveUserId(input)) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    url: localStorage.getItem('userId'),
                    appIdentifier: 'stampcard'  // アプリ識別子
                })
            };
            fetch('/backend/app.py', requestOptions)
                .then(response => response.json())
                .then(json => {
                    if (json.error) {
                        console.error('Error: ', json.message);
                        setErrorMessage(json.message);
                    } else {
                        setData(json);
                    }
                })
                .catch(error => {
                    console.error('Error fetching data: ', error);
                    setErrorMessage('データの取得に失敗しました。');
                });
        }
    };

    return (
        <div style={{ margin: '20px' }}>
            <TextField
                label="ロドストキャラクターページURL or キャラクターID（数字）"
                variant="outlined"
                fullWidth
                value={input}
                onChange={handleInputChange}
            />
            <Button
                style={{ marginTop: '20px' }}
                variant="contained"
                color="primary"
                onClick={fetchTitle}
            >
                アチーブ情報を取得
            </Button>
            {data ? (
                <div style={{ marginTop: '20px' }}>
                    <h2>{data.character_name} @ {data.home_world}</h2>

                    <List>
                        {data.achievements.map((item, index) => (
                            <Card key={index} style={styles.card}>
                                <CardContent>
                        <ListItem>
                            <ListItemIcon>
                                {item.status === '達成済み' ?
                                    <CheckCircleIcon style={styles.achieved} /> :
                                    <CancelIcon style={styles.notAchieved} />
                                }{item.status}
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                                        {item.id}:{item.name}
                                        <Tooltip title={item.detailtxt} placement="top">
                                            <InfoIcon style={styles.infoIcon} />
                                        </Tooltip>

                                    </Typography>
                                }
                            />
                        </ListItem>
                                </CardContent>
                            </Card>
                        ))}
                    </List>
                </div>
            ) : null}
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </div>
    );
}

export default MyComponent;