// CustomThemeProvider.js
import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';


const theme = createTheme({
	palette: {
		text: {
			secondary: '#00000099',
		},
		button: {
			secondary: '#00000066',
		},
		background: {
			default: '#99eecc',
		},
		primary: {
			main: '#0066CC', // AppBarの色をここでカスタマイズ
		},
		secondary: {
			main: '#000000', // AppBarの色をここでカスタマイズ
		},
	},
	components: {
	  MuiButton: {
		styleOverrides: {
		  root: { // すべてのボタンに適用されるスタイル
/* 			fontWeight: 'bold', */
		  },
		  textPrimary: { // primary variantのボタン特有のスタイル
			color: '#00000099',
		  },
		},
	  },
	},
});
const CustomThemeProvider = ({ children }) => {
	return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default CustomThemeProvider;
