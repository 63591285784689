import React, { useState, useEffect } from 'react';
import { TextField, Grid, Container, Box, Typography } from '@mui/material';
import StampCard from './components/StampCard';
import DownloadButton from './components/DownloadButton';
import { MESSAGES } from '../../constants';
import CardMembershipIcon from '@mui/icons-material/CardMembership'; // カードメンバーシップアイコン
import cardBackground from './assets/stamp-card-background.png';

// 画像のプリロードを行う関数
const preloadImage = (src) => {
  const img = new Image();
  img.src = src;
};


const StampCardApp = () => {
  const [loading, setLoading] = useState(true); // ボタンのローディング状態

  useEffect(() => {
    // 背景画像をプリロード
    preloadImage(cardBackground);
    // 1秒後にダウンロードボタンを有効にする
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    // コンポーネントのアンマウント時にタイマーをクリア
    return () => clearTimeout(timer);
  }, []);
  // 初期値を設定
  const [appState, setAppState] = useState(() => {
    // localStorageから状態を読み込む
    const savedState = localStorage.getItem('appState');
    return savedState ? JSON.parse(savedState) : { playerName: '', selectedStamps: new Array(10).fill(false) };
  });

  // 状態が変更されたらlocalStorageに保存
  useEffect(() => {
    localStorage.setItem('appState', JSON.stringify(appState));
  }, [appState]);

  // プレイヤー名とスタンプの状態を更新するハンドラー
  const handlePlayerNameChange = (event) => {
    setAppState(prevState => ({ ...prevState, playerName: event.target.value }));
  };

  const handleStampChange = (newStamps) => {
    setAppState(prevState => ({ ...prevState, selectedStamps: newStamps }));
  };

  return (

    <div>
      <h1><CardMembershipIcon sx={{ mr: 1 }} />漁師PTアチブスタカ</h1>

      {/* スタンプカードの表示 */}

      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8} >
            <StampCard playerName={appState.playerName} selectedStamps={appState.selectedStamps} onStampChange={handleStampChange} />

          </Grid>
          <Grid item xs={12} md={4} >

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} >
                <Box
                  sx={{
                    bgcolor: 'background.paper', // 背景色
                    borderRadius: '8px', // 角丸の大きさ
                    p: 2, // 内側のパディング（spacing単位）
                    maxWidth: 300, // 最大幅
                  }}
                >
                  <Typography variant="body2" color="text.secondary" sx={{
                    marginBottom: '6px', lineHeight: 1.2
                  }}>
                    クリア済みのスタンプ枠をクリック
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{
                    lineHeight: 1.2
                  }}>
                    おなまえを入力（空欄可）
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      fontSize: '0.75rem', // 文字サイズを小さくする
                      marginTop: '10px', // 上のマージンを10ピクセルに設定
                      lineHeight: 1.2
                    }}
                  >
                    #FF14 #FF14漁師PTアチブスタカ
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={12} >
                <TextField
                  label="おなまえ"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  sx={{
                    maxWidth: '300px', // 最大幅を設定
                  }}
                  value={appState.playerName}
                  onChange={handlePlayerNameChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={12} >
                <DownloadButton
                  disabled={loading}
                  loading={loading}
                  elementId="stampCard"
                  fileName="stamp-card"

                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <p><small>{MESSAGES.USAGE_NOTICE}</small></p>
    </div>

  );
};
export default StampCardApp;